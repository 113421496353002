import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const baseURL = process.env.REACT_APP_CEX_SERVICE;
const apiVersion = process.env.REACT_APP_CEX_API_VERSION;

export const api = axios.create({
  baseURL: `${baseURL}/${apiVersion}/user`,
});

export const apiUtils = axios.create({
  baseURL: `${baseURL}/utils`,
});

export const apiKyc = axios.create({
  baseURL: `${baseURL}/${apiVersion}/user`,
});

// websocket token
export function getWebsocketToken() {
  const websocketToken = secureLocalStorage.getItem('websocketToken');
  try {
    if (websocketToken !== null || websocketToken !== undefined) {
      return websocketToken;
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
}

// refresh token
async function getRefreshToken() {
  const refreshToken = secureLocalStorage.getItem('refreshToken');
  try {
    const resp = await axios.post(
      `${baseURL}/${apiVersion}/user/refresh`,
      {},
      {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    return resp.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    const accessToken = secureLocalStorage.getItem('accessToken');
    //console.log("accessToken: " + accessToken);
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
  }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    // check & store tokens
    const refreshToken = response.data?.refreshToken;
    const accessToken = response.data?.accessToken;
    const websocketToken = response.data?.websocketToken;

    if (refreshToken) {
      secureLocalStorage.setItem('refreshToken', refreshToken);
    }

    if (accessToken) {
      secureLocalStorage.setItem('accessToken', accessToken);
    }

    if (websocketToken) {
      secureLocalStorage.setItem('websocketToken', websocketToken);
    }

    return response;
  },
  async function (error) {
    const statusCode = error.response?.status;
    const responseData = error.response?.data;
    const errorMsg = responseData?.error?.message;

    if (statusCode === 401 && errorMsg === 'Invalid authentication.') {
      console.log('Token has been revoked...');

      // refresh tokens
      const tokens = await getRefreshToken();
      if (tokens) {
        const refreshToken = tokens?.refreshToken;
        const accessToken = tokens?.accessToken;
        const websocketToken = tokens?.websocketToken;

        secureLocalStorage.setItem('refreshToken', refreshToken);
        secureLocalStorage.setItem('accessToken', accessToken);
        secureLocalStorage.setItem('websocketToken', websocketToken);

        // re-send request with new token
        const originalRequest = error.config;
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

apiUtils.interceptors.request.use(
  async (config) => {
    const accessToken = secureLocalStorage.getItem('accessToken');
    //console.log("accessToken: " + accessToken);
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

apiUtils.interceptors.response.use(
  (response) => {
    // check & store tokens
    const refreshToken = response.data?.refreshToken;
    const accessToken = response.data?.accessToken;
    const websocketToken = response.data?.websocketToken;

    if (refreshToken) {
      secureLocalStorage.setItem('refreshToken', refreshToken);
    }

    if (accessToken) {
      secureLocalStorage.setItem('accessToken', accessToken);
    }

    if (websocketToken) {
      secureLocalStorage.setItem('websocketToken', websocketToken);
    }

    return response;
  },
  async function (error) {
    const statusCode = error.response?.status;
    const responseData = error.response?.data;
    const errorMsg = responseData?.error?.message;

    if (statusCode === 401 && errorMsg === 'Invalid authentication.') {
      console.log('Token has been revoked...');

      // refresh tokens
      const tokens = await getRefreshToken();
      if (tokens) {
        const refreshToken = tokens?.refreshToken;
        const accessToken = tokens?.accessToken;
        const websocketToken = tokens?.websocketToken;

        secureLocalStorage.setItem('refreshToken', refreshToken);
        secureLocalStorage.setItem('accessToken', accessToken);
        secureLocalStorage.setItem('websocketToken', websocketToken);

        // re-send request with new token
        const originalRequest = error.config;
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

// Request interceptor for API calls
apiKyc.interceptors.request.use(
  async (config) => {
    config.headers = {
      'X-API-KEY': 'user_api_key',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
