import { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const MAX_FILE_SIZE_MB = 12;
const MAX_NAME_LENGTH = 50;

export default function FileUploadButton({
  content,
  type,
  onUpload,
  disabled,
  hiddenUploadBtn,
}) {
  const inputRef = useRef(null);
  // const displayAttr = hiddenUploadBtn ? 'none' : 'inline-flex';
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function resetInputRef() {
    // 需要透過 useRef 來指定元件在上傳檔案後，重製 input 的 value，不然連續上傳兩個相同的檔案會導致 value 一樣，而沒有觸發 onChange
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const regex = /^[A-Za-z0-9_\.]+$/; // eslint-disable-line

    if (files.length) {
      // 確定有上傳的檔案
      if (files[0].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        // 檢查檔案大小
        resetInputRef();
        setOpen(true);
        setAlertMessage(`檔案大小不可超過 ${MAX_FILE_SIZE_MB} MB.`);
        return;
      } else if (!regex.test(files[0].name)) {
        // 檢查檔案名稱
        resetInputRef();
        setOpen(true);
        setAlertMessage(
          `檔案名稱僅能包含以下元素：英文大小寫、數字、底線(_)、點(.)`,
        );
        return;
      } else if (files[0].name.length > 50) {
        // 檢查檔案長度
        resetInputRef();
        setOpen(true);
        setAlertMessage(`檔名(含副檔名)需小於${MAX_NAME_LENGTH}個字元`);
        return;
      }
    }

    const newImages = files.map((file) => ({
      id: uuidv4(),
      file,
      url: URL.createObjectURL(file),
    }));

    onUpload(newImages);

    resetInputRef();
    setOpen(true);
    setAlertMessage(`檔案上傳成功`);
  };

  return (
    <>
      <Button
        disabled={disabled}
        disableElevation
        component="label"
        variant="contained"
        startIcon={
          type === 'camera' ? <AddAPhotoIcon /> : <AddPhotoAlternateIcon />
        }
        color="primary"
        sx={{
          display: hiddenUploadBtn ? 'none' : 'inline-flex',
          borderRadius: '4px',
          color: '#FFFFFF',
          height: 42,
          lineHeight: '42px',
          border: '1px solid #CFCFCF',
          boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
        }}>
        {content}
        <VisuallyHiddenInput
          type="file"
          capture={type === 'camera' ? 'environment' : null}
          accept="image/*"
          ref={inputRef}
          onChange={handleFileChange}
        />
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
