import { useEffect, useState, useReducer, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import moment from 'moment';

import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Icon from '../../components/Icon';
import DescriptionLabel from './components/DescriptionLabel';
import DigitLabelBox from './components/DigitLabelBox';
import { Label } from '../../components/Label';
import HiddenTextField from './components/HiddenTextField';
import {
  maxVerifyCodeLength,
  phoneVerifyCodeReducer as reducer,
} from '../../reducers/phoneVerifyCodeReducer';
import StyledButton from '../../components/StyledButton';
import BottomPanel from '../../components/BottomPanel';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import { useContext } from 'react';
import { UserContext } from '../../UserContext';

const initState = {
  numbers: [{ value: '|', animated: true }].concat(
    Array(maxVerifyCodeLength - 1).fill({ value: '', animated: false }),
  ),
  currentPos: 0,
  verifyDisable: true,
};

const countDown = moment.duration(3 * 60 * 1000);

export default function ResetPasswordOtpPage() {
  const [verifyCode, dispatch] = useReducer(reducer, initState);
  const { setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [disabledResend, setDisabledResend] = useState(true);
  const [duration, setDuration] = useState(countDown);
  const [showCountDown, setShowCountDown] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const textInput = useRef(null);
  const intervalRef = useRef();
  const dialogMessage = useRef();

  const handleKeyDown = (event) => {
    if (event.key.match(/^[0-9]$/)) {
      dispatch({ type: 'addNumber', payload: event.key });
    } else if (event.key === 'Backspace') {
      dispatch({ type: 'delete' });
    }
  };

  const handleResendVerifyCode = async () => {
    setLoading(true);
    const response = await UserAPI.resendForgetPasswordVerifyCode();
    setLoading(false);
    if (response?.error) {
      dialogMessage.current = response?.error?.message;
      setShowDialog(true);
    } else {
      setDuration(countDown);
      setShowCountDown(true);
      setDisabledResend(true);
      dispatch({ type: 'reset' });
    }
  };

  const hadleVerifyCode = async () => {
    setLoading(true);
    const payload = {
      code: verifyCode.numbers.map((number) => number.value).join(''),
    };
    console.log(payload)
    const response = await UserAPI.verifyCodeForgetPassword(payload);
    setLoading(false);
    if (response?.error) {
      dialogMessage.current = response?.error?.message;
      setShowDialog(true);
    } else {
      const page = response?.page
      console.log(page)
      navigate(page, {
        state: { entry: '/resetPassword/otp' },
      });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setDuration(moment.duration(duration.asMilliseconds() - 1000));
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [duration]);

  useEffect(() => {
    if (duration.asMilliseconds() < 0) {
      clearInterval(intervalRef.current);
      setDisabledResend(false);
      setShowCountDown(false);
    }
  }, [duration]);

  useEffect(() => {
    if (state?.entry !== '/resetPassword/phoneNumber') {
      navigate("/signIn")
    }
  }, [])

  return (
    <>
      <Box sx={{ pt: `${navigationBarHeight}px` }}>
        <NavigationBar
          title="請輸入手機簡訊驗證碼"
          leftButtonHandler={() => {
            navigate('/signIn');
          }}
        />
      </Box>
      <Stack
        spacing={0}
        direction="column"
        alignItems="center"
        marginTop="40px">
        <Icon src="img_phone.png" width={122} height={120} />
        <DescriptionLabel width={'85%'} marginTop="40px">
          我們已傳送驗證碼到你的手機號碼{state?.data?.phoneNumber}
          ，<br />
          請在下方輸入驗證碼，謝謝
        </DescriptionLabel>
        <Box
          display="flex"
          marginTop="32px"
          // click數字框重新focus(小網、APP)
          onClick={() => {
            textInput.current.focus();
          }}
        >
          {verifyCode.numbers.map((number, index) => {
            return (
              <DigitLabelBox
                key={index}
                value={number.value}
                animated={number.animated}
                isEditing={index === verifyCode.currentPos}
              />
            );
          })}
        </Box>
        {/* 隱藏的input*/}
        <HiddenTextField inputRef={textInput} />
        <Box>
          {showCountDown ? (
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center">
              <span style={{ color: '#FF8F08' }}>
                {String(duration.minutes()).padStart(2, '0')}
                &nbsp;:&nbsp;
                {String(duration.seconds()).padStart(2, '0')}
              </span>
              &nbsp;後驗證碼過期
            </Label>
          ) : (
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center">
              驗證碼已過期
            </Label>
          )}
        </Box>
        <Box display="flex" justifyContent="center" width="100%" marginY="16px">
          <StyledButton
            variant="contained"
            fullWidth
            disableElevation
            disabled={disabledResend}
            onClick={handleResendVerifyCode}
            sx={{
              height: '48px',
              marginX: '16px',
            }}>
            重新寄送驗證碼
          </StyledButton>
        </Box>
      </Stack>
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={hadleVerifyCode}
          disabled={verifyCode.verifyDisable}>
          驗證
        </StyledButton>
      </BottomPanel>
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="錯誤訊息"
        message={dialogMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowDialog(false)}
      />
    </>
  );
}
