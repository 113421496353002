import React, { useEffect, useState, useRef, useContext } from 'react';
import useWebSocket from 'react-use-websocket';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Paper } from '@mui/material';
import { XYChart, Tooltip, AreaSeries, GlyphSeries } from '@visx/xychart';
import { LinearGradient } from '@visx/gradient';
import moment from 'moment/moment';
import Decimal from 'decimal.js';
import Dialog from '../../components/Dialog';
import BottomPanel from '../../components/BottomPanel';
import Panel from '../../components/Panel';
import NavigationBar from '../../components/NavigationBar';
import GroupButtons from '../../components/GroupButtons';
import AssetRecordCell from '../../components/asset/AssetRecordCell';

import { navigationBarHeight } from '../../components/Layout';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import { ProductAPI } from '../../apis/ProductAPI';
import { OrderAPI } from '../../apis/OrderAPI';
import { UserContext } from '../../UserContext';
import {
  CoinNumberFormat,
  PriceNumberFormat,
  ThousandSeparator,
} from '../../utils/CoinNumberFormat';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';

const periodLabels = ['1日', '1週', '1月', '3月', '1年', '全部'];
const periodIds = ['1D', '7D', '1M', '3M', '1Y', 'ALL'];
const gradientTopColor = '#cccccc';
const gradientBottomColor = '#FFF';

const accessors = {
  xAccessor: (d) => d.x,
  yAccessor: (d) => d.y,
};

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionTitleLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: '17px',
  fontWeight: 'bold',
  marginBottom: '16px',
}));

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: '13px',
  fontWeight: 'medium',
}));

const BuySellButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const ViewOrdersButton = styled(Button)(({ theme }) => ({
  color: '#070707',
  fontSize: '14px',
  fontWeight: 'medium',
}));

export default function CoinPricePage() {
  const { setLoading, user, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const productId = params.productId;
  const initData = useRef(false);
  const startPosition = useRef({ x: 0, y: 0 });
  const [product, setProduct] = useState(null);
  const [orders, setOrders] = useState([]);
  const [chartData, setChartData] = useState({
    seriesData: [],
    minMaxData: [],
    minValue: 0,
    maxValue: 0,
  });
  const [buyPrice, setBuyPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => false,
    queryParams: {
      token: getWebsocketToken(),
    },
  });
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const unpaidOrderRef = useRef(null);
  const [totalSize, setTotalSize] = useState(null);

  const tradeBtnTitle = [
    { side: '購買', sx: { marginRight: '8px' }, disable: false },
    {
      side: '賣出',
      sx: { marginLeft: '8px' },
      disable: totalSize === 0 ? true : false,
    },
  ];

  useEffect(() => {
    async function fetchProductData() {
      const product = await ProductAPI.getProduct(productId);
      setProduct(product);
    }

    async function fetchOrderData() {
      if (user) {
        setLoading(true);
        const allOrders = await OrderAPI.getOrders();
        const productOrders = allOrders.filter(
          (order) => order.productId === productId,
        );
        const validOrders = productOrders.filter(
          (order) => order.status === 'done' && order.doneReason === 'succeed',
        );
        const reducedSize = validOrders.reduce((accumulator, order) => {
          return order.side === 'buy'
            ? accumulator.plus(order.size)
            : accumulator.minus(order.size);
        }, new Decimal(0));
        setOrders(productOrders);
        setTotalSize(reducedSize.toNumber());
        setLoading(false);
      }
    }

    if (initData.current === false) {
      initData.current = true;
      fetchProductData();
      fetchOrderData();
    }

    if (lastMessage) {
      const data = JSON.parse(lastMessage?.data);
      if (data['id'] && data['status']) {
        return;
      }

      const realtimePrices = data;
      const priceData = realtimePrices[product.id]['balance'];
      setBuyPrice(priceData.buy_price);
      setSellPrice(priceData.sell_price);
    } else if (product) {
      const priceData = product.realtimePrice.balance;
      setBuyPrice(priceData.buyPrice);
      setSellPrice(priceData.sellPrice);
    }
  }, [productId, product, lastMessage, orders, user]);

  async function fetchPriceChartData(period) {
    const prices = await ProductAPI.getPrice(productId, period);
    let seriesData = [];
    let maxPrice = 0;
    let minPrice = Number.MAX_VALUE;
    let maxDataPoint = {};
    let minDataPoint = {};
    for (let i = 0; i < prices.length; i++) {
      const priceData = prices[i];
      const price = ['USDC-TWD', 'USDT-TWD'].includes(productId)
        ? Math.round(priceData.totalPrice * 10000) / 10000
        : Math.round(priceData.totalPrice);
      const strDate = moment
        .unix(priceData.datetime)
        .format('YYYY-MM-DD HH:mm');
      const dataPoint = { x: strDate, y: price };
      seriesData.push(dataPoint);
      if (price > maxPrice) {
        maxPrice = price;
        maxDataPoint = dataPoint;
      }
      if (price < minPrice && price > 0) {
        minPrice = price;
        minDataPoint = dataPoint;
      }
    }

    setTimeout(function () {
      setChartData({
        seriesData: seriesData,
        minMaxData: [maxDataPoint, minDataPoint],
        minValue: minPrice,
        maxValue: maxPrice,
      });
    }, 100);
  }

  const handleBack = () => {
    setTabIndex(0);
    setNavTitle('OP加密資產存摺');
    navigate('/');
  };

  const handleSelectPriceRange = (index) => {
    setLoading(true);
    fetchPriceChartData(periodIds[index]);
    setLoading(false);
  };

  const handleTradeCoins = (side) => {
    const unpaidOrder = orders?.find((order) => checkUnpaidOrder(order));
    if (unpaidOrder !== undefined) {
      unpaidOrderRef.current = unpaidOrder;
      setShowOrderDialog(true);
    } else {
      if (side === '購買') navigate('/buy/' + productId);
      if (side === '賣出') navigate('/sell/' + productId);
    }
  };

  const checkUnpaidOrder = (order) => {
    if (
      (order.payment === 'store' || order.payment === 'bank') &&
      order.status === 'open'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOrderInfo = (order) => {
    if (checkUnpaidOrder(order)) {
      navigate('/orderPayment', { state: { order, cancelOrder: true } });
    } else {
      navigate('/orderInfo', { state: { order } });
    }
  };

  const navigation = () => {
    const title = product?.baseCurrencyId
      ? `${product?.baseCurrencyId}/TWD`
      : `${productId}`.replace('-', '/');
    const icon = product?.baseCurrencyId
      ? `../../images/${product?.baseCurrencyId}.png`
      : '';
    return (
      <NavigationBar
        title={title}
        titleIcon={icon}
        leftButtonHandler={handleBack}
      />
    );
  };

  const subNavigation = () => {
    return (
      <Paper square variant="string">
        <Box padding="8px">
          <TitleLabel fontSize="12px" fontWeight="medium" textAlign="center">
            最佳價格(餘額扣款)
          </TitleLabel>
        </Box>
      </Paper>
    );
  };

  const currentPrice = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="16px"
        marginBottom="16px"
        marginLeft="10vw"
        marginRight="10vw">
        <Box display="flex" alignItems="center" flexDirection="column">
          <SubTitleLabel fontSize="15px" fontWeight="regular">
            即時買價 TWD
          </SubTitleLabel>
          <TitleLabel fontSize="24px" fontWeight="bold">
            <PriceNumberFormat value={buyPrice} />
          </TitleLabel>
        </Box>

        <Box display="flex" alignItems="center" flexDirection="column">
          <SubTitleLabel fontSize="15px" fontWeight="regular">
            即時賣價 TWD
          </SubTitleLabel>
          <TitleLabel fontSize="24px" fontWeight="bold">
            <PriceNumberFormat value={sellPrice} />
          </TitleLabel>
        </Box>
      </Box>
    );
  };
  const calcPointX = (tooltipData) => {
    const length = ThousandSeparator(tooltipData.datum.y).length;
    const shift = 10;
    return (accessors.xAccessor(tooltipData) / window.innerWidth) * 100 > 50
      ? accessors.xAccessor(tooltipData) - (length * 7 + shift)
      : accessors.xAccessor(tooltipData) + shift;
  };

  const priceHistory = () => {
    return (
      <Panel>
        <TitleLabel fontSize="14px" paddingTop="14px" paddingLeft="14px">
          歷史買價趨勢(餘額扣款)
        </TitleLabel>
        <GroupButtons
          defaultSelection={0}
          items={periodLabels}
          selectionHandler={handleSelectPriceRange}
        />

        <XYChart
          height={300}
          xScale={{ type: 'band' }}
          yScale={{
            type: 'linear',
            domain: [chartData?.minValue, chartData?.maxValue],
            zero: false,
          }}
          margin={{ top: 10, right: -10, bottom: 10, left: -10 }}
          onPointerDown={(d) => {
            startPosition.current = {
              x: d.svgPoint.x,
              y: d.svgPoint.y,
            };
          }}
          onPointerMove={(d) => {
            const diffX = d.svgPoint.x - startPosition.current.x;
            const diffY = d.svgPoint.y - startPosition.current.y;

            if (Math.abs(diffX) > 10 && Math.abs(diffY) < 20) {
              document.body.style.overflow = 'hidden';
            } else {
              document.body.style.overflow = 'scroll';
            }
          }}
          onPointerUp={() => (document.body.style.overflow = 'scroll')}
          onPointerOut={() => (document.body.style.overflow = 'scroll')}>
          <LinearGradient
            id="area-gradient"
            from={gradientTopColor}
            to={gradientBottomColor}
          />
          <AreaSeries
            dataKey={product?.baseCurrencyId}
            data={chartData?.seriesData}
            {...accessors}
            fill="url(#area-gradient)"
          />
          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            showVerticalCrosshair
            renderTooltip={({ tooltipData, colorScale }) => (
              <div>
                <div
                  style={{
                    color: colorScale(tooltipData.nearestDatum.key),
                  }}>
                  {tooltipData.nearestDatum.key}
                </div>
                {accessors.xAccessor(tooltipData.nearestDatum.datum)}
                {', '}
                {ThousandSeparator(
                  accessors.yAccessor(tooltipData.nearestDatum.datum),
                )}
              </div>
            )}
          />
          <GlyphSeries
            data={chartData?.minMaxData}
            {...accessors}
            enableEvents={false}
            renderGlyph={(tooltipData) => (
              <g>
                <circle
                  cx={accessors.xAccessor(tooltipData)}
                  cy={accessors.yAccessor(tooltipData)}
                  r="4"
                  stroke="black"
                  strokeWidth="2"
                  fill="white"
                />
                <text
                  textAnchor="start"
                  dy={'8px'}
                  fill="black"
                  fontSize={14}
                  x={calcPointX(tooltipData)}
                  y={accessors.yAccessor(tooltipData)}>
                  {ThousandSeparator(tooltipData.datum.y)}
                </text>
              </g>
            )}
          />
        </XYChart>
      </Panel>
    );
  };

  const totalHoldCoins = () => {
    return (
      <>
        {user && (
          <Box marginTop="23px">
            <Panel>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ m: '20px' }}>
                <TitleLabel fontSize="15px" fontWeight="medium">
                  持有{product?.baseCurrency.name}數量
                </TitleLabel>
                <TitleLabel fontSize="17px" fontWeight="bold">
                  {/* <CoinNumberFormat value={totalSize} suffix=" " /> */}
                  {totalSize} {product?.baseCurrencyId}
                </TitleLabel>
              </Box>
            </Panel>
          </Box>
        )}
      </>
    );
  };

  const orderRecords = () => {
    const maxItems = 5;
    let items = [];
    const max = orders.length > maxItems ? maxItems : orders.length;
    for (let i = 0; i < max; i++) {
      const order = orders[i];

      items.push(
        <AssetRecordCell
          key={i}
          price={order.size}
          time={moment.unix(order.createdTime).format('YYYY/MM/DD HH:mm A')}
          status={getOrderState(order)}
          side={order.side}
          product={product?.baseCurrencyId}
          onClick={() => handleOrderInfo(order)}
        />,
      );
    }

    return (
      <>
        {items.length > 0 && (
          <Box marginTop="37px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <TitleLabel fontSize="18px" fontWeight="medium">
                交易紀錄
              </TitleLabel>

              <ViewOrdersButton
                onClick={() =>
                  navigate(`/assetHistory/${product?.baseCurrencyId}`, {
                    state: { from: '/market/' + productId },
                  })
                }>
                查看全部
              </ViewOrdersButton>
            </Box>

            <Box sx={{ height: '8px' }}></Box>
            {items}
          </Box>
        )}
      </>
    );
  };

  const getOrderState = (order) => {
    let state = 'pending';
    if (order.status === 'done') {
      if (order.doneReason === 'succeed') {
        state = 'success';
      } else if (order.doneReason === 'rejected') {
        state = 'cancel';
      } else if (order.doneReason === 'cancel') {
        state = 'cancel';
      }
    }
    return state;
  };

  const coinDescription = () => {
    return (
      <Box marginTop="23px" marginBottom="90px">
        <DescriptionTitleLabel>
          {product?.baseCurrency.name} 介紹
        </DescriptionTitleLabel>
        <DescriptionLabel>
          比特幣是一種去中心化的虛擬貨幣，也就是大家熟知的數位加密貨幣。作為一種有別於實體貨幣形式的「網上貨幣」，它是通過網絡傳輸的方式進行資金轉帳。在過去的幾年時間裡，比特幣在消費者、政府和金融交易者中的地位已經得到逐步提升。現在比特幣是全球最大的數位加密貨幣，這不僅是就市值而言，同時還體現在它的知名度以及吸引力方面。
        </DescriptionLabel>
      </Box>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        {tradeBtnTitle.map((btn) => (
          <BuySellButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => handleTradeCoins(btn.side)}
            sx={btn.sx}
            disabled={btn.disable}>
            {btn.side}
          </BuySellButton>
        ))}
      </BottomPanel>
    );
  };

  const verifyDialog = () => {
    return (
      <Dialog
        title="身分驗證中"
        message="您好，系統正在驗證您的身分，驗證完成後才可開始進行交易，謝謝。"
        showDialog={showVerifyDialog}
        setShowDialog={setShowVerifyDialog}
        actionLabel="確認"
        actionHandler={() => {
          setShowVerifyDialog(false);
        }}
      />
    );
  };

  const orderDialog = () => {
    return (
      <Dialog
        showDialog={showOrderDialog}
        setShowDialog={setShowOrderDialog}
        title="訂單尚未完成"
        message="您有尚未繳款的訂單，請先完成該筆訂單"
        actionLabel="前往訂單"
        actionHandler={() => {
          handleOrderInfo(unpaidOrderRef.current);
        }}
        secondaryActionLabel="確認"
        secondaryActionHandler={() => {
          setShowOrderDialog(false);
        }}
      />
    );
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}
      {subNavigation() /* subNavigation bar */}
      <Box padding="16px">
        {currentPrice() /* 即時買/賣價格 */}
        {priceHistory() /* 歷史幣價曲線表 */}
        {totalHoldCoins() /* 持有虛擬貨幣數量 */}
        {orderRecords() /* 交易紀錄 */}
        {coinDescription() /* 虛擬貨幣介紹 */}
        {bottomPanel() /* 購買 / 賣出按鈕 */}
      </Box>
      {verifyDialog()}
      {orderDialog()}
    </Box>
  );
}
