import { api, apiUtils, apiKyc } from './configs/axiosConfigs';

export const UserAPI = {
  getUserToken: async function (payload) {
    try {
      const response = await api.request({
        url: '/login/csapp',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },

  // 取得 user 資料
  getUser: async function () {
    try {
      const response = await api.request({
        url: '/me/',
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  getUserWithPhoneAndPassword: async function (payload) {
    try {
      const response = await api.request({
        url: '/login/phone-password',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  // 驗證用戶身分證號碼，並使用開戶資料更新會員資料
  postAuthorizeTwmData: async function (payload) {
    try {
      const response = await api.request({
        url: '/authorize-twm-data',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },

  // 確認用戶註冊並進行kyc
  postRegister: async function (payload) {
    try {
      const response = await api.request({
        url: '/register',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  phoneVerifyCode: async function (payload) {
    try {
      const response = await api.request({
        url: '/me/phone/verify-code',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  // 重新寄送驗證信
  resendPhoneVerifyCode: async function () {
    try {
      const response = await apiUtils.request({
        url: '/notify',
        method: 'POST',
        data: {
          reason_type: 'register', // 寄送簡訊
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  // 檢查信箱驗證碼
  emailVerifyCode: async function (payload) {
    try {
      const response = await api.request({
        url: '/me/email/verify-code',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },

  // 重新寄送驗證信
  resendEmailVerifyCode: async function () {
    try {
      const response = await api.request({
        url: '/me/email/verify-code',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return false;
    }
  },

  // 取得信箱驗證碼過期時間
  getEmailExpireTime: async function () {
    try {
      const response = await api.request({
        url: '/me/email/expire-time',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return false;
    }
  },

  getPhoneExpireTime: async function () {
    try {
      const response = await api.request({
        url: '/me/phone/expire-time',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  // 更新 user 信箱
  updateEmail: async function (payload) {
    try {
      const response = await api.request({
        url: '/me/email',
        method: 'PUT',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  getStoreDepositInfo: async function () {
    try {
      const response = await api.request({
        url: '/me/deposit/store',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  // 取得銀行清單
  getBankList: async function () {
    try {
      const response = await api.request({
        url: '/banks/codes',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  // 取得分行清單
  getBankBranchList: async function (bankCode) {
    try {
      const response = await api.request({
        url: `/banks/${bankCode}/branch-codes`,
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  // 綁定銀行帳戶
  setBankAccount: async function (payload) {
    try {
      const response = await api.request({
        url: '/me/bank-account',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  // 取得用戶入金記錄
  getDepositRecords: async function () {
    const response = await api.request({
      url: '/me/deposit-records',
      method: 'GET',
    });
    return response.data;
  },
  // 取得用戶出金記錄
  getWithdrawRecords: async function () {
    const response = await api.request({
      url: '/me/withdraw-records',
      method: 'GET',
    });
    return response.data;
  },
  // 取得用戶帳單資訊
  getBillInfo: async function () {
    const response = await api.request({
      url: '/me/bill-info',
      method: 'GET',
    });
    return response.data;
  },
  // 更新用戶帳單資訊
  updateBillInfo: async function (payload) {
    try {
      const response = await api.request({
        url: '/me/bill-info',
        method: 'PUT',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  // 取得用戶剩餘額度
  getPaymentInfo: async function (productId, side) {
    const response = await api.request({
      url: `/me/payment-info?product_id=${productId}&side=${side}`,
      method: 'GET',
    });

    return response.data;
  },
  // 取得小平台歸戶網址
  getEipUrl: async function () {
    const response = await api.request({
      url: '/me/eip/as-is',
      method: 'GET',
    });
    return response.data;
  },
  // 大平台載具歸戶確認 email 並寄送驗證碼
  getEipEmailCode: async function (payload) {
    await api.request({
      url: '/eip/email',
      method: 'POST',
      data: payload,
    });

    return true;
  },
  // 輸入驗證碼，取得大平台歸戶網址
  verifyEipEmailCode: async function (payload) {
    try {
      const response = await api.request({
        url: '/eip/verify',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
  resendWithdrawVerifyCode: async function () {
    try {
      const response = await api.request({
        url: '/me/sms/verify-code',
        method: 'POST',
        data: {
          reason_type: 'withdraw', // 寄送簡訊
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  withdrawVerifyCode: async function (payload) {
    try {
      const response = await api.request({
        url: '/me/withdraw',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  availableWithdrawAmount: async function () {
    try {
      const response = await api.request({
        url: '/me/withdraw/available',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getWithdrawExpireTime: async function () {
    try {
      const response = await api.request({
        url: '/me/phone/expire-time?verify_type=withdraw',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getWithdrawLimitation: async function () {
    try {
      const response = await api.request({
        url: '/me/withdraw/verify-code/reach-limit',
        method: 'GET',
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getKycUrl: async function () {
    try {
      const response = await api.request({
        url: '/kyc/auth',
        method: 'POST',
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getKycResult: async function (payload) {
    try {
      const response = await apiKyc.request({
        url: '/kyc/callback',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  forgetPassword: async function (payload) {
    try {
      const response = await api.request({
        url: '/forgot-password',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  forgetPasswordVerifyIdentityNumber: async function (payload) {
    try {
      const response = await api.request({
        url: '/forgot-password/verify-identity-number',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  resendForgetPasswordVerifyCode: async function () {
    try {
      const response = await api.request({
        url: '/forgot-password/resend',
        method: 'POST',
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  resetPassword: async function (payload) {
    try {
      const response = await api.request({
        url: '/forgot-password/reset',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  verifyCodeForgetPassword: async function (payload) {
    try {
      const response = await api.request({
        url: '/forgot-password/verify-code',
        method: 'POST',
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};