import {
    useState,
    useEffect,
    useRef,
    useReducer,
    useContext,
    useCallback,
} from "react";
import { Box, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { navigationBarHeight } from "../../components/Layout";
import { UserAPI } from "../../apis/UserAPI";
import DigitLabelBox from "./components/DigitLabelBox";
import DescriptionLabel from "./components/DescriptionLabel";
import HiddenTextField from './components/HiddenTextField';
import Icon from "../../components/Icon";
import Dialog from "../../components/Dialog";
import { Label } from "../../components/Label";
import StyledButton from "../../components/StyledButton";
import BottomPanel from "../../components/BottomPanel";
import NavigationBar from "../../components/NavigationBar";
import { UserContext } from "../../UserContext";
import deepCopy from "../../utils/deepCopy";
import { maxVerifyCodeLength, phoneVerifyCodeReducer as reducer } from "../../reducers/phoneVerifyCodeReducer";

const initState = {
    numbers: [{ value: "|", animated: true }].concat(
        Array(maxVerifyCodeLength - 1).fill({ value: "", animated: false })
    ),
    currentPos: 0,
    verifyDisable: true,
};

export default function PhoneVerifyPage() {
    const [verifyCode, dispatch] = useReducer(reducer, initState, deepCopy);
    const navigate = useNavigate();
    const { setLoading, user, setUser, setTabIndex } = useContext(UserContext);
    const { state } = useLocation();
    const [disableResend, setDisableResend] = useState(true);
    const [showCountDown, setShowCountDown] = useState(false);
    const [countDownDate, setCountDownDate] = useState(
        moment.unix(moment.now())
    ); // 現在時間
    const intervalRef = useRef(); // 用來存 setInterval 的 id
    const [duration, setDuration] = useState(
        moment.duration(countDownDate.diff(countDownDate))
    ); // 初始值 duration 是 0
    const [showAlert, setShowAlert] = useState(false);
    const [showResendFailAlert, setShowResendFailAlert] = useState(false);
    const alertTitle = useRef("");
    const alertMessage = useRef("");
    const textInput = useRef(null);

    const handleBack = () => {
        setTabIndex(0);
        navigate("/");
    };

    const handleKeyDown = (event) => {
        if (event.key.match(/^[0-9]$/)) {
            dispatch({ type: "addNumber", payload: event.key });
        } else if (event.key === "Backspace") {
            dispatch({ type: "delete" });
        }
    };

    const handleResendVerifyCode = useCallback(async () => {
        setShowAlert(false);
        setLoading(true);
        const response = await UserAPI.resendPhoneVerifyCode();
        setLoading(false);
        if (response?.error) {
            alertMessage.current = response?.error.message;
            setShowResendFailAlert(true);
            dispatch("reset");
        } else {
            setCountDownDate(moment.unix(response?.expiredTime));
            setShowCountDown(true);
            setDisableResend(true);
            dispatch("reset");
        }
    }, [setLoading]);

    const handleGetPhoneExpireTime = useCallback(async () => {
        setShowAlert(false);
        setLoading(true);
        const response = await UserAPI.getPhoneExpireTime();
        setLoading(false);

        if (response?.error) {
            handleResendVerifyCode();
        } else {
            setCountDownDate(moment.unix(response.expiredTime));
            setShowCountDown(true);
            setDisableResend(true);
            dispatch("reset");
        }
    }, [setLoading, handleResendVerifyCode]);

    const handleVerifyCode = async () => {
        setLoading(true);
        const response = await UserAPI.phoneVerifyCode({
            code: verifyCode.numbers.map((number) => number.value).join(""),
        });
        setLoading(false);

        if (response?.error) {
            alertTitle.current = "驗證失敗";
            alertMessage.current = response?.error.message;
            setShowAlert(true);
        } else {
            setUser(response);
            navigate("/emailVerify", { state: { entry: state.entry } });
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setDuration(moment.duration(countDownDate.diff(moment.now())));
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [countDownDate]);

    useEffect(() => {
        if (duration.asMilliseconds() < 0) {
            clearInterval(intervalRef.current);
            setDisableResend(false);
            setShowCountDown(false);
        }
    }, [duration]);

    useEffect(() => {
        handleGetPhoneExpireTime();
    }, [handleGetPhoneExpireTime]);

    return (
        <>
            <Box sx={{ pt: `${navigationBarHeight}px` }}>
                <NavigationBar
                    title='輸入手機驗證碼'
                    leftButtonHandler={handleBack}
                />
            </Box>
            <Stack
                spacing={0}
                direction='column'
                alignItems='center'
                marginTop='40px'
            >
                <Icon
                    src='img_phone.png'
                    width={122}
                    height={120}
                />

                <DescriptionLabel
                    width={"85%"}
                    marginTop='40px'
                >
                    我們已傳送驗證碼到你的手機號碼{user?.phoneNumber}
                    ，<br/>請在下方輸入驗證碼，謝謝
                </DescriptionLabel>
                              
                <Box
                    display='flex'
                    marginTop='32px'
                    // click數字框重新focus
                    onClick={() => {
                        textInput.current.focus();
                    }}
                >
                    {verifyCode.numbers.map((number, index) => {
                        return (
                            <DigitLabelBox
                                key={index}
                                value={number.value}
                                animated={number.animated}
                                isEditing={index === verifyCode.currentPos}
                            />
                        );
                    })}
                </Box>

                {/* 隱藏的input*/}
                <HiddenTextField 
                  inputRef={textInput} 
                  value={verifyCode}
                />

                <Box>
                    {showCountDown ? (
                        <Label
                            fontSize='15px'
                            fontWeight='bold'
                            color='#717171'
                            align='center'
                        >
                            <span style={{ color: "#FF8F08" }}>
                                {String(duration.minutes()).padStart(2, "0")}
                                &nbsp;:&nbsp;
                                {String(duration.seconds()).padStart(2, "0")}
                            </span>
                            &nbsp;後驗證碼過期
                        </Label>
                    ) : (
                        <Label
                            fontSize='15px'
                            fontWeight='bold'
                            color='#717171'
                            align='center'
                        >
                            驗證碼已過期請選擇
                        </Label>
                    )}
                </Box>
                <Box
                    display='flex'
                    justifyContent='center'
                    width='100%'
                    marginY='16px'
                >
                    <StyledButton
                        variant='contained'
                        fullWidth
                        disableElevation
                        sx={{
                            height: "48px",
                            marginX: "16px",
                        }}
                        disabled={disableResend}
                        onClick={handleResendVerifyCode}
                    >
                        重新寄送驗證碼
                    </StyledButton>
                </Box>
            </Stack>

            <Dialog
                showDialog={showAlert}
                setShowDialog={setShowAlert}
                title={alertTitle.current}
                message={alertMessage.current}
                actionLabel='重新寄送'
                actionHandler={handleResendVerifyCode}
            />
            <Dialog
                showDialog={showResendFailAlert}
                setShowDialog={setShowResendFailAlert}
                title='重新寄送失敗'
                message={alertMessage.current}
                actionLabel='確定'
                actionHandler={() => setShowResendFailAlert(false)}
            />
            <BottomPanel>
                <StyledButton
                    variant='contained'
                    fullWidth
                    disableElevation
                    onClick={handleVerifyCode}
                    disabled={verifyCode.verifyDisable}
                >
                    驗證
                </StyledButton>
            </BottomPanel>
        </>
    );
}