import { useMemo, useState, useContext } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';

import { UserContext } from './UserContext';
import { useRedirectPath } from './hooks/useRedirectPath';

import Layout from './components/Layout';
import MarketPage from './pages/market/MarketPage';
import AssetPage from './pages/asset/AssetPage';
import MemberPage from './pages/member/MemberPage';
import FAQPage from './pages/member/FAQPage';
import CoinPricePage from './pages/market/CoinPricePage';
import BuyPage from './pages/trade/BuyPage';
import SellPage from './pages/trade/SellPage';
import OrderPaymentPage from './pages/trade/OrderPaymentPage';
import SignUpPage from './pages/account/SignUpPage';
import AssetHistoryPage from './pages/asset/AssetHistoryPage';
import Loading from './components/Loading';
import EmailVerifyPage from './pages/account/EmailVerifyPage';
import KycVerifyingPage from './pages/account/KycVerifyingPage';
import KycFailedPage from './pages/account/KycFailedPage';
import InvoiceSettingPage from './pages/account/InvoiceSettingPage';
import TradeDonePage from './pages/trade/TradeDonePage';
import OrderInfoPage from './pages/trade/OrderInfoPage';
import PrivacyPage from './pages/terms/PrivacyPage';
import UserTermsPage from './pages/terms/UserTermsPage';
import DisclaimerPage from './pages/terms/DisclaimerPage';
import LoginPage from './pages/account/LoginPage';
import ChangeEmailPage from './pages/account/ChangeEmailPage';
import BankDepositPage from './pages/trade/BankDepositPage';
import StoreDepositPage from './pages/trade/StoreDepositPage';
import InvoiceInfoPage from './pages/trade/InvoiceInfoPage';
import BankAccountPage from './pages/account/BankAccountPage';
import BankMenuPage from './pages/account/BankMenuPage';
import BankWithdrawPage from './pages/trade/BankWithdrawPage';
import BankWithdrawConfirmPage from './pages/trade/BankWithdrawConfirmPage';
import BankWithdrawDonePage from './pages/trade/BankWithdrawDonePage';
import BankStatusPage from './pages/account/BankStatusPage';
import ChangePhoneBarcodePage from './pages/account/ChangePhoneBarcodePage';
import ChangeCitizenDigitalPage from './pages/account/ChangeCitizenDigitalPage';
import ChangeAccountInfoPage from './pages/account/ChangeAccountInfoPage';
import ChangeDoneeCodePage from './pages/account/ChangeDoneeCodePage';
import EipEmailPage from './pages/account/EipEmailPage';
import EipEmailVerifyPage from './pages/account/EipEmailVerifyPage';
import DepositDonePage from './pages/trade/DepositDonePage';
import LandingPage from './pages/account/LandingPage';
import SignInPage from './pages/account/SignInPage';
import PhoneVerifyPage from './pages/account/PhoneVerifyPage';
import SignUpCompletedPage from './pages/account/SignUpCompletedPage';
import KycSucceedPage from './pages/account/KycSucceedPage';
import ChangePhoneNumberPage from './pages/account/ChangePhoneNumberPage';
import ChangePhoneNumberSucceedPage from './pages/account/ChangePhoneNumberSucceedPage';
import KycLoadingPage from './pages/account/KycLoadingPage';
import EddPage from './pages/account/EddPage';
import ScrollToTop from './components/ScrollToTop';
import KycReverifyPage from './pages/account/KycReverifyPage';
import ResetPasswordPhoneNumberPage from './pages/account/ResetPasswordPhoneNumberPage';
import ResetPasswordIdentityPage from './pages/account/ResetPasswordIdentityPage';
import ResetPasswordOtpPage from './pages/account/ResetPasswordOtpPage';
import ResetPasswordPage from './pages/account/ResetPasswordPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF8E15',
    },
    secondary: {
      main: '#6C757D',
    },
    background: {
      //default: "#FF00FFFF"
      default: '#F8F9FA',
    },
    text: {
      primary: '#070707',
      secondary: '#6C757D',
    },
    info: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: 'roboto',
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 900,
  },
});

const routes = [
  { path: '/landing', element: <LandingPage /> },
  { path: '/signIn', element: <SignInPage /> },
  { path: '/signUp', element: <SignUpPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/', element: <MarketPage /> },
  { path: '/asset', element: <AssetPage /> },
  { path: '/member', element: <MemberPage /> },
  { path: '/faq', element: <FAQPage /> },
  { path: '/market/:productId', element: <CoinPricePage /> },
  { path: '/buy/:coin', element: <BuyPage /> },
  { path: '/sell/:coin', element: <SellPage /> },
  { path: '/emailVerify', element: <EmailVerifyPage /> },
  { path: '/eipEmail', element: <EipEmailPage /> },
  { path: '/eipEmailVerify', element: <EipEmailVerifyPage /> },
  { path: '/changeEmail', element: <ChangeEmailPage /> },
  { path: '/changeInvoice', element: <InvoiceSettingPage /> },
  { path: '/changePhoneBarcode', element: <ChangePhoneBarcodePage /> },
  { path: '/changeCitizenDigital', element: <ChangeCitizenDigitalPage /> },
  { path: '/changeAccountInfo', element: <ChangeAccountInfoPage /> },
  { path: '/changeDoneeCode', element: <ChangeDoneeCodePage /> },
  { path: '/kycVerifying', element: <KycVerifyingPage /> },
  { path: '/kycFailed', element: <KycFailedPage /> },
  { path: '/kycReverify', element: <KycReverifyPage /> },
  { path: '/assetHistory/:defaultFilter', element: <AssetHistoryPage /> },
  { path: '/orderPayment', element: <OrderPaymentPage /> },
  { path: '/tradeDone', element: <TradeDonePage /> },
  { path: '/orderInfo', element: <OrderInfoPage /> },
  { path: '/invoiceInfo', element: <InvoiceInfoPage /> },
  { path: '/privacy', element: <PrivacyPage /> },
  { path: '/terms', element: <UserTermsPage /> },
  { path: '/disclaimer', element: <DisclaimerPage /> },
  { path: '/deposit/bank', element: <BankDepositPage /> },
  { path: '/deposit/store', element: <StoreDepositPage /> },
  { path: '/deposit/done', element: <DepositDonePage /> },
  { path: '/bankAccount', element: <BankAccountPage /> },
  { path: '/bankMenu', element: <BankMenuPage /> },
  { path: '/bankStatus', element: <BankStatusPage /> },
  { path: '/withdraw/bank', element: <BankWithdrawPage /> },
  { path: '/withdraw/bank/confirm', element: <BankWithdrawConfirmPage /> },
  { path: '/withdraw/bank/done', element: <BankWithdrawDonePage /> },
  { path: '/phoneVerify', element: <PhoneVerifyPage /> },
  { path: '/signUpCompleted', element: <SignUpCompletedPage /> },
  { path: '/changePhoneNumber', element: <ChangePhoneNumberPage /> },
  {
    path: '/changePhoneNumberSucceed',
    element: <ChangePhoneNumberSucceedPage />,
  },
  { path: '/kycSucceed', element: <KycSucceedPage /> },
  { path: '/kycLoading', element: <KycLoadingPage /> },
  { path: '/edd', element: <EddPage /> },
  { path: '/resetPassword', element: <ResetPasswordPage /> },
  { path: '/resetPassword/phoneNumber', element: <ResetPasswordPhoneNumberPage /> },
  { path: '/resetPassword/identity', element: <ResetPasswordIdentityPage />},
  { path: '/resetPassword/otp', element: <ResetPasswordOtpPage /> },
  { path: '*', element: <Navigate to="/" /> },
];

const CheckPath = ({ pathName, children }) => {
  const { user } = useContext(UserContext);
  const { redirectPath, state } = useRedirectPath(pathName);

  const {
    isRegistered,
    phoneVerifyStatus,
    emailVerifyStatus,
    kycStatus,
    amlStatus,
    bankAccount,
  } = user || {};
  const bankAccountStatus = bankAccount?.status;

  // 頁面導頁邏輯參考: https://www.figma.com/board/gEL78OVVw5DBEUNKENaH3Q/EVE-%E6%9C%83%E5%93%A1%E8%A8%BB%E5%86%8A%E7%99%BB%E5%85%A5%E5%8F%8A%E8%BA%AB%E5%88%86%E9%A9%97%E8%AD%89%E8%A6%8F%E5%8A%83%E6%96%87%E4%BB%B6?node-id=457-4111&t=Cx2nKWRsaqzRZlW4-0
  // 完全沒有驗證的人可以看的頁面
  let verifiedPaths = [
    // 一個只做轉導的頁面，沒有實質內容，用意是在台灣大客服APP轉導到我們的內嵌網站時，取得 APP 帶給我們的 user 加密過後的資料
    // ，我們會把這個資料從URL上取得，並丟到後端去解析 user 資料，來進行 自動化的 user 驗證
    '/login',
    '/', // 首頁
    '/landing', // 打招呼頁 (可以導到登入註冊頁)
    '/signIn', // 登入帳號密碼輸入頁
    '/signUp', // 會員註冊頁
    '/phoneVerify', // 手機號碼驗證頁
    '/emailVerify', // 信箱驗證頁
    '/changeEmail', // 更改信箱頁
    '/kycVerifying', // KYC 驗證等待頁
    // '/kycSucceed', // KYC驗證完成頁
    '/kycFailed', // KYC驗證失敗頁
    '/market/:productId', // 加密貨幣幣價頁
    '/changePhoneNumber', // 換手機頁
    '/changePhoneNumberSucceed', // 手機更換成功頁
    '/kycLoading', // 目前不知道用在哪
    '/privacy', // 隱私權條款
    '/terms', // 使用者條款
    '/disclaimer', // 免責聲明
    '/edd', // 目前開發要用的白名單
    '/resetPassword/phoneNumber', // 忘記密碼: 輸入手機號碼
    '/resetPassword/otp', // 忘記密碼: 簡訊OTP驗證
    '/resetPassword/identity', // 忘記密碼: 輸入身分證字號
    '/resetPassword' // 忘記密碼: 重設密碼
  ];

  if (
    (phoneVerifyStatus === 'intraVerified' ||
      phoneVerifyStatus === 'interVerified') &&
    emailVerifyStatus === 'succeed'
  ) {
    verifiedPaths = [
      ...verifiedPaths,
      '/faq', // 常見問題
      '/signUpCompleted', // 註冊完成頁（用來接 kyc 流程）
    ];

    if (amlStatus !== 'blocked') {
      // 狀態p、q
      verifiedPaths = [
        ...verifiedPaths,
        '/member', // 會員中心首頁
      ];
    }

    if (
      !(kycStatus === 'unverified' && amlStatus === 'unverified') && // 狀態a
      !(kycStatus === 'succeed' && amlStatus === 'awaitingInput') && // 狀態e
      !(kycStatus === 'pendingReview' && amlStatus === 'awaitingInput') && // 狀態f
      !(kycStatus === 'unverified' && amlStatus === 'succeed') && // 狀態m
      !(
        (kycStatus === 'unverified' || kycStatus === 'succeed') && // 狀態p、q
        amlStatus === 'blocked'
      )
    ) {
      verifiedPaths = [
        ...verifiedPaths,
        '/changeAccountInfo', // 基本資料
        '/changeInvoice', // 發票載具設定
        '/changePhoneBarcode', // 手機條碼載具
        '/changeCitizenDigital', // 自然人憑證
        '/changeDoneeCode', // 發票愛心捐贈
      ];
      if (
        !(
          (kycStatus === 'unverified' || kycStatus === 'succeed') && // 狀態n、o
          amlStatus === 'denied'
        )
      ) {
        verifiedPaths = [
          ...verifiedPaths,
          '/bankAccount', // 進行銀行綁定頁
          '/bankMenu', // 選擇銀行清單頁
          '/bankStatus', // 銀行狀態頁
        ];
      }
    }
  }

  // AML拒絕往來只能賣幣和提領(狀態n、o)
  if (
    (kycStatus === 'succeed' || kycStatus === 'unverified') &&
    amlStatus === 'denied'
  ) {
    verifiedPaths = [
      ...verifiedPaths,
      '/sell/:coin', // 賣出頁
      '/tradeDone', // 交易完成頁
      '/orderInfo', // 訂單詳情頁
      '/deposit/done', // 儲值詳情頁
      '/withdraw/bank/done', // 提領詳情頁
      // 台幣提領相關
      '/withdraw/bank',
      '/withdraw/bank/confirm',
      '/withdraw/bank/done',
      
    ];
  }

  // 開放資產頁(狀態n、o、r、s、t、u)
  if (
    ((kycStatus === 'succeed' || kycStatus === 'unverified') &&
      amlStatus === 'denied') ||
    (kycStatus === 'succeed' && amlStatus === 'succeed')
  ) {
    verifiedPaths = [
      ...verifiedPaths,
      // 我的資產相關
      '/asset', // 我的資產
      '/assetHistory/:defaultFilter',
    ]
  }

  // AML審核成功(SUCCEED)，KYC人工審核失敗狀態(打回UNVERIFIED)，導向身分認證失敗重新驗證頁
  if (kycStatus === 'unverified' && amlStatus === 'succeed') {
    verifiedPaths = [...verifiedPaths, '/kycReverify'];
  }

  // KYC、AML通過後，不論銀行綁定狀態如何，都開放以下頁面
  if (kycStatus === 'succeed' && amlStatus === 'succeed') {
    verifiedPaths = [...verifiedPaths, '/kycSucceed'];
  }

  const isPathVerified = verifiedPaths.includes(pathName);
  console.log(verifiedPaths);

  // 確認這個人是否完成所有認證(完成就跳過所有轉導，讓使用者去任何想去的頁面)
  const isIdentityVerified =
    isRegistered &&
    (phoneVerifyStatus === 'intraVerified' ||
      phoneVerifyStatus === 'interVerified') &&
    emailVerifyStatus === 'succeed' &&
    kycStatus === 'succeed' &&
    amlStatus === 'succeed' &&
    bankAccountStatus === 'succeed';

  return isPathVerified || isIdentityVerified ? (
    children
  ) : (
    <Navigate to={redirectPath} state={state} />
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const { path, element } = route;
        return (
          <Route
            key={path}
            path={path}
            element={<CheckPath pathName={path}>{element}</CheckPath>}
          />
        );
      })}
    </Routes>
  );
};

function App() {
  const [navTitle, setNavTitle] = useState('OP加密資產存摺');
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [user, setUser] = useState(null);
  const value = useMemo(
    () => ({
      navTitle,
      setNavTitle,
      loading,
      setLoading,
      tabIndex,
      setTabIndex,
      user,
      setUser,
    }),
    [navTitle, tabIndex, loading, user],
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <UserContext.Provider value={value}>
          <CssBaseline />
          <Layout>
            <AppRoutes />
          </Layout>
          {/* Loading */}
          <Loading enabled={loading} />
        </UserContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
