import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { React, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomPanel from '../../components/BottomPanel';
import Dialog from '../../components/Dialog';
import { Label, SubTitleLabel, TitleLabel } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import Panel from '../../components/Panel';
import { UserContext } from '../../UserContext';
// import { useEffectOnce } from '../../utils/UseEffectOnce';
import { getBankName } from '../../utils/BankCode';
import { UserAPI } from '../../apis/UserAPI';
import { PriceNumberFormat } from '../../utils/CoinNumberFormat';

const NextButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: '#6C757D',
  fontSize: '15px',
  fontWeight: '400',
}));

export default function BankWithdrawPage() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [disable, setDisable] = useState(true);
  const { user, setLoading } = useContext(UserContext);
  const { state } = useLocation();
  const { accounts } = state;
  const [availableBalance, setAvailableBalance] = useState(false);
  const inputAmount = useRef();
  const alertMessage = useRef('');
  const bankCode = user?.bankAccount?.bankCode;
  const [bankName, setBankName] = useState('');
  const [availableWithdrawAmount, setAvailableWithdrawAmount] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function getAvailableWithdrawAmount() {
      setLoading(true);
      const response = await UserAPI.availableWithdrawAmount();
      setLoading(false);
      setAvailableWithdrawAmount(response);
    }
    getAvailableWithdrawAmount();
  }, [setLoading]);

  useEffect(() => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].currencyId === 'TWD') {
        setAvailableBalance(accounts[i].availableBalance);
      }
    }
  }, [accounts]);

  useEffect(() => {
    const _getBankName = async () => {
      const _bankName = await getBankName(bankCode);
      setBankName(_bankName);
    };
    _getBankName();
  }, [bankCode]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = async () => {
    let amount = parseInt(inputAmount.current.value);
    if (amount > Math.min(availableBalance, 1000000)) {
      alertMessage.current =
        '您的提領金額超出可提領額度，請確認後再次申請提領，謝謝';
      setShowAlert(true);
      return;
    } else if (amount < 16) {
      alertMessage.current = '提領金額不得低於16元';
      setShowAlert(true);
      return;
    }
    navigate('/withdraw/bank/confirm', {
      state: {
        amount: amount,
        bankAccountNumber: user?.bankAccount?.accountNumber,
      },
    });
  };

  const handleAmountUpdate = (e) => {
    const amount = e.target.value;
    const digitalAmount = Number(amount); // empty string 轉數字會為 0
    if (digitalAmount === 0) {
      setMessage('下一步');
      setDisable(true);
    } else if (
      digitalAmount <
      availableWithdrawAmount.withdrawTransactionLowerBound.single.amount
    ) {
      setMessage(
        availableWithdrawAmount.withdrawTransactionLowerBound.single.message,
      );
      setDisable(true);
    } else if (
      digitalAmount >
      availableWithdrawAmount.withdrawTransactionUpperBound.affordable.amount
    ) {
      setMessage(
        availableWithdrawAmount.withdrawTransactionUpperBound.affordable
          .message,
      );
      setDisable(true);
    } else if (
      digitalAmount >
      availableWithdrawAmount.withdrawTransactionUpperBound.single.amount
    ) {
      setMessage(
        availableWithdrawAmount.withdrawTransactionUpperBound.single.message,
      );
      setDisable(true);
    } else if (
      digitalAmount >
      availableWithdrawAmount.withdrawTransactionUpperBound.daily.amount
    ) {
      setMessage(
        availableWithdrawAmount.withdrawTransactionUpperBound.daily.message,
      );
      setDisable(true);
    } else if (
      digitalAmount >
      availableWithdrawAmount.withdrawTransactionUpperBound.monthly.amount
    ) {
      setMessage(
        availableWithdrawAmount.withdrawTransactionUpperBound.monthly.message,
      );
      setDisable(true);
    } else {
      setMessage('下一步');
      setDisable(
        amount === '' ||
          amount.length < 0 ||
          parseInt(amount) === 0 ||
          parseInt(amount) < 16,
      );
    }
  };

  const navigation = () => {
    return <NavigationBar title="提領台幣" leftButtonHandler={handleBack} />;
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <NextButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleNext}
          disabled={disable}>
          {message ? message : '下一步'}
        </NextButton>
      </BottomPanel>
    );
  };

  const bankDepositDesc = () => {
    return (
      <Box marginTop="10px" marginBottom="10px">
        <DescriptionLabel>單筆提領限額 1,000,000 TWD</DescriptionLabel>
      </Box>
    );
  };

  const bankDepositInfo = () => {
    return (
      <>
        <Panel>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            paddingTop="22px">
            <TitleLabel fontSize="17px">提領金額</TitleLabel>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            paddingTop="0px"
            paddingBottom="0px">
            <TextField
              type="number"
              autoComplete="off"
              fullWidth
              id="amount"
              placeholder="請填入提領金額"
              inputRef={inputAmount}
              onChange={handleAmountUpdate}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding="16px"
            paddingTop="22px">
            <DescriptionLabel fontSize="15px" pr="4px">
              帳戶餘額
            </DescriptionLabel>
            <DescriptionLabel fontSize="15px">
              <PriceNumberFormat value={availableBalance} />
              TWD
            </DescriptionLabel>
          </Box>
        </Panel>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt="16px"
          pb="16px">
          <Label fontSize="15px">提領款項將存入您的綁定銀行帳號</Label>
        </Box>
        <Panel>
          <Box display="flex" alignItems="center" padding="16px">
            <Label fontSize="15px">
              {bankName}
              {user?.bankAccount?.accountNumber}
            </Label>
          </Box>
        </Panel>
      </>
    );
  };

  const bankDepositCautions = () => {
    return (
      <Box marginTop="20px" marginBottom="90px">
        <SubTitleLabel>
          注意事項：
          <br />
          1.使用本服務時，請先確認您是從台灣大客服內使用OP加密資產存摺的服務，若您使用官方發布之外之應用程式造成任何損失，本服務的提供者不承擔任何責任。
          <br />
          2.台幣提領只能使用您綁定之銀行帳號。
          <br />
          3.當您申請提領後，無法再做更改或取消。
        </SubTitleLabel>
      </Box>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px">
          {bankDepositDesc()}
          {bankDepositInfo()}
          <Divider
            sx={{
              pt: '16px',
              ml: '-16px',
              mr: '-16px',
            }}
          />
          {bankDepositCautions()}
        </Box>
        {bottomPanel() /* 按鈕 */}
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="提領金額錯誤"
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </>
  );
}
