import { useState, useRef, useContext } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import BottomPanel from '../../components/BottomPanel';
import { UserContext } from '../../UserContext';
import ResultTemplate from './components/ResultTemplate';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

export default function SignUpCompletedPage() {
  const { setTabIndex, setNavTitle } = useContext(UserContext);
  const alertMessage = useRef('');
  const [showAlert, setShowAlert] = useState(false);
  const { setLoading } = useContext(UserContext);

  const navigate = useNavigate();

  const handleBack = () => {
    setTabIndex(0);
    setNavTitle("OP加密資產存摺");
    navigate('/');
  };

  // const handleComplete = () => {
  //     navigate("/");
  // };

  const handleRedirectToKyc = async () => {
    setShowAlert(false);
    setLoading(true);
    const response = await UserAPI.getKycUrl();
    setLoading(false);
    if (response?.error) {
      alertMessage.current = response?.error.message;
      setShowAlert(true);
    } else {
      window.location.replace(response.redirectUrl);
    }
  };

  return (
    <Box sx={{ pt: `${navigationBarHeight}px` }}>
      <NavigationBar
        title="身分驗證"
        leftButtonIcon={
          <CloseOutlinedIcon
            style={{ fontSize: 25 }}
            sx={{
              color: (theme) => theme.palette.common.black,
            }}
          />
        }
        leftButtonHandler={handleBack}
      />
      <Box>
        <ResultTemplate
          title="請繼續完成身分驗證"
          desc={
            '您已完成OP加密資產存摺會員註冊，請繼續身分驗證及綁定銀行帳號，以使用我們完整的服務'
          }
          succeed={true}
        />
      </Box>
      <BottomPanel>
        <TradeButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleRedirectToKyc}>
          開始身分驗證
        </TradeButton>
      </BottomPanel>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="KYC 頁面轉導失敗"
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </Box>
  );
}
