import { React, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Divider, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { SubTitleLabel } from '../../components/Label';
import { UserAPI } from '../../apis/UserAPI';
import Barcode from 'react-barcode';
import { useEffectOnce } from '../../utils/UseEffectOnce';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: '15px',
  fontWeight: '400',
}));

export default function StoreDepositPage() {
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);

  useEffectOnce(() => {
    async function updateInfo() {
      const _info = await UserAPI.getStoreDepositInfo();
      console.log(_info);
      setInfo(_info);
    }
    updateInfo();
  }, [info]);

  const handleBack = () => {
    //navigate("/asset");
    navigate(-1);
  };

  const navigation = () => {
    return <NavigationBar title="台幣儲值" leftButtonHandler={handleBack} />;
  };

  const storeDepositDesc = () => {
    return (
      <Box marginTop="10px" marginBottom="10px">
        <DescriptionLabel>
          請向台灣大哥大門市櫃檯人員出示條碼並告知要儲值的金額
        </DescriptionLabel>
      </Box>
    );
  };

  const storeDepostInfo = () => {
    return (
      <Stack padding={'32px'} spacing={2} alignItems="center">
        <Barcode
          height={65}
          width={3}
          background="#F8F9FA"
          value={info?.barcode1}
        />
        <Barcode
          height={65}
          width={3}
          background="#F8F9FA"
          value={info?.barcode2}
        />
      </Stack>
    );
  };

  const storeDepostCautions = () => {
    return (
      <Box marginTop="20px" marginBottom="90px">
        <SubTitleLabel>
          注意事項：
          <br />
          1.使用本服務時，請先確認您是從台灣大客服內使用OP加密資產存摺的服務，若您使用官方發布之外之應用程式造成任何損失，本服務的提供者不承擔任何責任。
          <br />
          2.儲值完成無法取消，請您明確告知櫃檯人員欲儲值的金額並以現金支付。
        </SubTitleLabel>
      </Box>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px">
          {storeDepositDesc()}
          {storeDepostInfo()}
          <Divider
            sx={{
              ml: '-16px',
              mr: '-16px',
            }}
          />
          {storeDepostCautions()}
        </Box>
      </Box>
    </>
  );
}
