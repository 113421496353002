import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, IconButton, Badge, Divider } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { UserContext } from "../UserContext";
import NavigationBar from "./NavigationBar";
import TabBar from "./TabBar";

import { notifyQuitApp } from "../utils/WebViewCallback";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "./Dialog";
import { SubTitleLabel } from "./Label";

export const layoutPaths = ["/", "/asset", "/member"];
export const navigationBarHeight = 64;
export const tabBarHeight = 64;

const landingPaths = ["/landing", "/signIn"];

const sxContainer = {
    height: "100vh",
    overflow: "scroll",
};

const ProviderInformation = () => {
    return (
        <Box margin='16px 0 90px'>
            <Divider
                variant='middle'
                component='div'
            />
            <SubTitleLabel
                textAlign='center'
                margin='16px'
            >
                本服務由富昇數位股份有限公司提供
                <br />
                24小時服務專線：(02)6635-1968
            </SubTitleLabel>
        </Box>
    );
};

const BtnNotification = () => {
    return (
        <IconButton
        // onClick={leftButtonHandler}
        >
            <Badge
                color='primary'
                badgeContent={0}
            >
                <NotificationsIcon
                    style={{ fontSize: 30 }}
                    sx={{ color: (theme) => theme.palette.common.black }}
                />
            </Badge>
        </IconButton>
    );
};

const LayoutEnabled = ({ children }) => {
    const { navTitle } = useContext(UserContext);
    const [showDialog, setShowDialog] = useState(false);

    const handleDismiss = () => {
        setShowDialog(true);
    };

    return (
        <Box
            sx={{
                ...sxContainer,
                p: `${navigationBarHeight}px 0 ${tabBarHeight}px 0`,
            }}
        >
            <NavigationBar
                title={navTitle}
                leftButtonHandler={handleDismiss}
                leftButtonIcon={
                    <CloseIcon
                        style={{ fontSize: 30 }}
                        sx={{
                            color: (theme) => theme.palette.common.black,
                        }}
                    />
                }
            >
                {/* <BtnNotification /> */}
            </NavigationBar>
            {children}
            <ProviderInformation />
            <TabBar />
            <Dialog
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                title='離開OP加密資產存摺'
                message='請確認是否要離開OP加密資產存摺?'
                actionLabel='離開'
                actionHandler={() => notifyQuitApp()}
                secondaryActionLabel='繼續使用'
                secondaryActionHandler={() => setShowDialog(false)}
            />
        </Box>
    );
};

const LayoutDisabled = ({ children }) => {
    return (
        <>
            {children}
            <ProviderInformation />
        </>
    );
};

const LayoutLanding = ({ children }) => {
    return (
        <Box
            sx={{
                ...sxContainer,
                background: "#FFF",
                p: `${navigationBarHeight}px 16px 42px`,
            }}
        >
            {children}
        </Box>
    );
};

export default function Layout({ children }) {
    const location = useLocation();
    const isLayoutPathsPage = layoutPaths.includes(location.pathname);
    const isLandingPage = landingPaths.includes(location.pathname);

    return (
        <>
            {isLayoutPathsPage ? (
                <LayoutEnabled>{children}</LayoutEnabled>
            ) : isLandingPage ? (
                <LayoutLanding>{children}</LayoutLanding>
            ) : (
                <LayoutDisabled>{children}</LayoutDisabled>
            )}
        </>
    );
}
