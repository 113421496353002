import { useContext } from 'react';
import { UserContext } from '../../UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import { notifyLoginSuccess } from '../../utils/WebViewCallback';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import secureLocalStorage from 'react-secure-storage';

export default function LoginPage() {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { search: queryUrl } = useLocation();
  const queryParams = new URLSearchParams(queryUrl);
  const csAppUserData = queryParams.get('data');

  useEffectOnce(() => {
    async function login(data) {
      const responseData = await UserAPI.getUserToken({ data });
      const { user, page } = responseData || {};
      const isRegistered = user?.isRegistered || false;

      // 1. 有電話號碼但未註冊
      // 2. 有電話號碼，且拿到要導轉的頁面是 "/changePhoneNumber"
      if (
        user?.phoneNumber &&
        (page === '/changePhoneNumber' || !isRegistered)
      ) {
        secureLocalStorage.setItem('phoneNumber', user.phoneNumber);
      }

      if (responseData && isRegistered) {
        setUser(user);
        notifyLoginSuccess();
        if (page) {
          return navigate(page);
        }
      }
    }

    if (csAppUserData) {
      secureLocalStorage.setItem('csAppUserData', csAppUserData);
      login(csAppUserData);
    }
    navigate('/');
  });
}
