// 倒數計時會造成整頁渲染，需要優化
import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, Button, Divider, InputBase } from '@mui/material';
import moment from 'moment/moment';

import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import BottomPanel from '../../components/BottomPanel';
import Dialog from '../../components/Dialog';
import Panel from '../../components/Panel';
import { SubTitleLabel, Label, TitleLabel } from '../../components/Label';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import { UserContext } from '../../UserContext';
import { UserAPI } from '../../apis/UserAPI';

const ConfirmButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const OTPButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '15px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: '#6C757D',
  fontSize: '15px',
  fontWeight: '400',
}));

export default function BankWithdrawConfirmPage() {
  const navigate = useNavigate();
  const { setLoading } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const { state } = useLocation();
  const { amount, bankAccountNumber } = state;
  const alertRef = useRef({});
  const fee = 15;
  const [verifyCode, setVerifyCode] = useState('');
  const [enableSendVefiyCode, setEnableSendVefiyCode] = useState(true);
  const intervalIdRef = useRef();
  const [duration, setDuration] = useState(60); // 倒數秒數預設60s
  const [enableWithdraw, setEnableWithdraw] = useState(true);
  const [isCountDowning, setIsCountDowning] = useState(false);
  const [numberOfAttemptToSendCode, setNumberOfAttemptToSendCode] = useState(0);
  const [numberOfavailableConfirmation, setNumberOfavailableConfirmation] =
    useState(0);

  async function initialize() {
    setLoading(true);

    const { isVerifyIncorrectOverLimit, isSendVerifyCountOverLimit } =
      await getWithdrawLimitation();

    if (!isVerifyIncorrectOverLimit && !isSendVerifyCountOverLimit) {
      await getWithdrawExpireTime();
    }

    setLoading(false);
  }

  async function getWithdrawLimitation() {
    const response = await UserAPI.getWithdrawLimitation();
    const isVerifyIncorrectOverLimit =
      response.isVerifyIncorrectOverLimit.result;
    const isSendVerifyCountOverLimit =
      response.isSendVerifyCountOverLimit.result;
    const sendVerifyCountRemain = response.isSendVerifyCountOverLimit.remain;
    const verifyIncorrectRemain = response.isVerifyIncorrectOverLimit.remain;
    const verifyIncorrectOverLimitMessage =
      response.isVerifyIncorrectOverLimit.message;
    const sendVerifyCountOverLimitMessage =
      response.isSendVerifyCountOverLimit.message;

    // 超過驗證碼錯誤上限次數，不能發送驗證碼，也不能確認提領
    if (isVerifyIncorrectOverLimit) {
      setEnableSendVefiyCode(false);
      setEnableWithdraw(false);
      alertRef.current = {
        title: '發送驗證碼',
        message: verifyIncorrectOverLimitMessage,
      };
      setShowAlert(true);
      // 超過驗證碼發送上限次數，不能再發送驗證碼，但還是可以確認提領
    } else if (isSendVerifyCountOverLimit) {
      setEnableSendVefiyCode(false);
      alertRef.current = {
        title: '發送驗證碼',
        message: sendVerifyCountOverLimitMessage,
      };
      setShowAlert(true);
      setNumberOfavailableConfirmation(verifyIncorrectRemain);
    } else {
      setNumberOfAttemptToSendCode(sendVerifyCountRemain);
      setNumberOfavailableConfirmation(verifyIncorrectRemain);
    }

    return { isVerifyIncorrectOverLimit, isSendVerifyCountOverLimit };
  }

  async function getWithdrawExpireTime() {
    const response = await UserAPI.getWithdrawExpireTime();
    // 驗證碼過期
    if (response?.error?.message === '驗證碼已失效') {
      setEnableSendVefiyCode(true);
    } else {
      // 驗證碼尚未到期，但倒數已超過1分鐘
      if (moment().unix() > Number(response.expiredTime) - 120) {
        setEnableSendVefiyCode(true);
      } else {
        setDuration(response.expiredTime - moment().unix() - 120);
        setEnableSendVefiyCode(false);
        setIsCountDowning(true);
      }
    }
  }

  function handleBack() {
    navigate(-1);
  }

  async function handleConfirm() {
    setLoading(true);

    const payload = { code: verifyCode, amount: amount };
    const response = await UserAPI.withdrawVerifyCode(payload);

    setLoading(false);

    if (response?.error) {
      alertRef.current = {
        title: '提領台幣',
        message: response?.error.message,
      };
      setNumberOfavailableConfirmation((prev) => prev - 1);
      if (numberOfavailableConfirmation === 1) setEnableSendVefiyCode(false);
    } else {
      navigate('/withdraw/bank/done', {
        state: {
          back: true,
          title: '提領申請已送出',
          bankRecord: response,
        },
      });
    }
  }

  // 發送驗證碼
  async function handleSendverifyCode() {
    setLoading(true);

    const response = await UserAPI.resendWithdrawVerifyCode();
    setIsCountDowning(true);

    if (response?.error) {
      alertRef.current.title = '發送驗證碼';
      alertRef.current.message = response?.error.message;
      setShowAlert(true);
    } else {
      setEnableSendVefiyCode(false);
      setNumberOfAttemptToSendCode((prev) => prev - 1);
    }

    setLoading(false);
  }

  // 進入頁面初始化
  useEffect(() => {
    initialize();
  }, []);

  // 倒數計時器
  // 1. 由isCountDowning判斷是否該啟動倒數計時器
  useEffect(() => {
    if (isCountDowning) {
      intervalIdRef.current = setInterval(() => {
        setDuration((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalIdRef.current);
  }, [isCountDowning]);

  // 倒數結束(duration<=0)
  // 1. 清除interval
  // 2. 重設倒數秒數
  // 3. 設定現在不需繼續倒數
  // 4. 如果驗證碼發送次數已達上限或驗證碼錯誤已達上限則不允許再發送驗證碼;反之可以發送
  useEffect(() => {
    if (duration <= 0) {
      clearInterval(intervalIdRef.current);
      setDuration(60);
      setIsCountDowning(false);
      if (
        numberOfAttemptToSendCode <= 0 ||
        numberOfavailableConfirmation <= 0
      ) {
        setEnableSendVefiyCode(false);
      } else {
        setEnableSendVefiyCode(true);
      }
    }
  }, [duration]);

  function BankDepositConfirmButton() {
    // 確認提領按鈕是否能按
    // 1. 如果達到驗證碼錯誤次數上限則無法按
    // 2. 輸入的驗證碼不符合格式也不能按
    
    let disabled;
    if (!enableWithdraw || numberOfavailableConfirmation <= 0) {
      disabled = true;
    } else {
      disabled = !/^\d{6}$/.test(verifyCode);
    }

    return (
      <BottomPanel>
        <ConfirmButton
          variant="contained"
          fullWidth
          disableElevation
          disabled={disabled}
          onClick={handleConfirm}>
          確認提領
        </ConfirmButton>
      </BottomPanel>
    );
  }

  return (
    <>
      <Box sx={sxContainer}>
        <NavigationBar title="提領台幣" leftButtonHandler={handleBack} />
        <Box padding="16px">
          <BankDepositDesc key="depositDesc" />
          <BankDepositInfo
            key="depositInfo"
            enableSendVefiyCode={enableSendVefiyCode}
            numberOfAttemptToSendCode={numberOfAttemptToSendCode}
            numberOfavailableConfirmation={numberOfavailableConfirmation}
            duration={duration}
            amount={amount}
            bankAccountNumber={bankAccountNumber}
            fee={fee}
            setVerifyCode={setVerifyCode}
            verifyCode={verifyCode}
            handleSendverifyCode={handleSendverifyCode}
          />
          <Divider
            sx={{
              pt: '16px',
              ml: '-16px',
              mr: '-16px',
            }}
          />
          <BankDepositCautions key="depositCautions" />
        </Box>
        <BankDepositConfirmButton key="depositConfirm" />
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertRef.current.title}
        message={alertRef.current.message}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </>
  );
}

function BankDepositDesc() {
  return (
    <Box marginTop="10px" marginBottom="10px">
      <DescriptionLabel>單日提領限額 2,000,000 TWD</DescriptionLabel>
    </Box>
  );
}

function BankDepositCautions() {
  return (
    <Box marginTop="20px" marginBottom="90px">
      <SubTitleLabel>
        注意事項：
        <br />
        1.使用本服務時，請先確認您是從台灣大客服內使用OP加密資產存摺的服務，若您使用官方發布之外之應用程式造成任何損失，本服務的提供者不承擔任何責任。
        <br />
        2.台幣提領只能使用您綁定之銀行帳號。
        <br />
        3.當您申請提領後，無法再做更改或取消。
      </SubTitleLabel>
    </Box>
  );
}

function BankDepositInfo({
  enableSendVefiyCode,
  numberOfAttemptToSendCode,
  numberOfavailableConfirmation,
  duration,
  amount,
  bankAccountNumber,
  fee,
  setVerifyCode,
  verifyCode,
  handleSendverifyCode,
}) {
  // 發送驗證碼按鈕是否能按
  // 1. 如果已達發送驗證碼次數上限或驗證碼錯誤次數上限族不能按，並顯示稍後再試
  // 2. 按下發送驗證碼按鈕後顯示倒數秒數
  // 3. 其餘情況可按發送驗證碼按鈕
  let buttonText;
  if (
    !enableSendVefiyCode &&
    (numberOfAttemptToSendCode <= 0 || numberOfavailableConfirmation <= 0)
  ) {
    buttonText = '稍後再試';
  } else if (!enableSendVefiyCode) {
    buttonText = `重發(${parseInt(duration)})S..`;
  } else {
    buttonText = '發送驗證碼';
  }

  return (
    <>
      <Panel>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="16px"
          pr="16px"
          pb="8px"
          pt="22px">
          <Label fontSize="15px">提領金額</Label>
          <Label fontSize="15px">
            <CoinNumberFormat value={amount} /> TWD
          </Label>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="16px"
          pr="16px"
          pt="8px"
          pb="8px">
          <Label fontSize="15px">存入帳號</Label>
          <Label fontSize="15px">{bankAccountNumber}</Label>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="16px"
          pr="16px"
          pt="8px"
          pb="8px">
          <Label fontSize="15px">手續費</Label>
          <Label fontSize="15px">{fee} TWD</Label>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="16px"
          pr="16px"
          pt="8px"
          pb="22px">
          <TitleLabel fontSize="17px">實際到帳</TitleLabel>
          <TitleLabel fontSize="17px">
            <CoinNumberFormat value={amount - fee} /> TWD
          </TitleLabel>
        </Box>
      </Panel>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt="16px"
        pb="16px">
        <Label fontSize="15px">簡訊驗證</Label>
      </Box>
      <Panel>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="16px">
          <InputBase
            fullWidth
            autoFocus
            inputProps={{ maxLength: 6 }}
            placeholder="輸入簡訊驗證碼"
            value={verifyCode}
            onChange={(event) => {
              setVerifyCode(event.target.value);
            }}
          />
          <OTPButton
            disableElevation
            variant="contained"
            onClick={handleSendverifyCode}
            disabled={!enableSendVefiyCode}
            sx={{ minWidth: '125px' }}>
            {buttonText}
          </OTPButton>
        </Box>
      </Panel>
    </>
  );
}
