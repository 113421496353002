import { Snackbar, Button } from '@mui/material';
import { styled } from '@mui/system';

const CopyButton = styled(Button)(({ theme }) => ({
  color: '#646464',
  borderColor: '#909090',
  fontSize: '15px',
  padding: '2px',
  marginLeft: '5px',
  '&:hover, &:focus, &:active': {
    borderColor: '#909090',
  },
}));

export default function CopyToClipboardButton({ openCopyMessage, onClick }) {
  return (
    <>
      <CopyButton variant="outlined" onClick={onClick}>
        複製
      </CopyButton>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openCopyMessage}
        autoHideDuration={5000}
        onClose={onClick}
        message="訂單號碼已複製"
        key={'bottomCenter'}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: 'center',
          },
        }}
      />
    </>
  );
}
