import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import { useState, useEffect, useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';

export default function KycLoading() {
  const { setUser } = useContext(UserContext);
  const alertMessage = useRef('');
  const [showAlert, setShowAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getKycResult() {
      const payload = {
        sessionId: searchParams.get('sessionid'),
        result: searchParams.get('result'),
        kycTid: searchParams.get('kycTid'),
      };
      // console.log(payload);
      const response = await UserAPI.getKycResult(payload);
      // const response = {
      //   user: {
      //     id: 78,
      //     phoneNumber: '092****718',
      //     chineseName: '蔡*力',
      //     identityNumber: 'A11****111',
      //     email: 'eric492718@gmail.com',
      //     virtualAccountNumber: '61533000000789',
      //     isRegistered: true,
      //     phoneVerifyStatus: 'inter_verified',
      //     emailVerifyStatus: 'succeed',
      //     kycStatus: 'succeed',
      //     amlStatus: 'succeed',
      //     createdTime: 1700633046,
      //     updatedTime: 1705658059,
      //     bankAccount: {
      //       bankCode: '012',
      //       branchNumber: '3604',
      //       accountNumber: '123456789012',
      //       status: 'succeed',
      //     },
      //   },
      //   accessToken:
      //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiaWRlbnRpdHlfdHlwZSI6InVzZXIiLCJyb2xlIjpudWxsLCJzdWIiOiI3OCIsImp0aSI6ImFjMmFjZjA3LWQwNjMtNDNkMy1hODJkLTk2MzdmMTJmZGQ2MiIsImV4cCI6MTcxMTU5MzY2N30.o2uf2bvom8nUWLP_NbpMfzeFivJudS1gTzPW6JIu6z4',
      //   refreshToken:
      //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImlkZW50aXR5X3R5cGUiOiJ1c2VyIiwicm9sZSI6bnVsbCwic3ViIjoiNzgiLCJqdGkiOiJlZjYxNmUzYy0xMGI3LTRmMDYtODNhOC0yN2E4OGMyM2NjYmIiLCJleHAiOjE3MTEwNzUyNjd9.nmpF4dUYaWxu1IGFjg6Wq5ogOJ0aXPEYLAufS4waPvk',
      //   websocketToken:
      //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoid2Vic29ja2V0IiwiaWRlbnRpdHlfdHlwZSI6InVzZXIiLCJyb2xlIjpudWxsLCJzdWIiOiI3OCIsImp0aSI6ImQ0NTJiMjA2LWVjYTAtNGFjMy1iOTg4LTk4ODM3NWM2OThmMyIsImV4cCI6MTcxMTU5MzY2N30.pC6TLQz_aNR-o-rGneucIbkMElqXvRW-BqKw5wzsLN0',
      //   tokenType: 'bearer',
      //   page: '/kycSucceed', // kycSucceed, kycVerifying, edd
      // };

      if (response?.error) {
        alertMessage.current = response?.error.message;
        setShowAlert(true);
      } else if (response?.user) {
        setUser(response.user);
        setTimeout(() => navigate(response.page, { replace: true }), 5000);
      }
    }

    getKycResult();
  }, [setShowAlert, searchParams]);

  return (
    <>
      <Box sx={{ pt: `${navigationBarHeight}px` }}>
        <NavigationBar title="身分驗證中" hideLeftButton={true} />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '400px' }}>
        <CircularProgress size={100} />
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="身分驗證失敗"
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => {
          navigate('/signUpCompleted');
        }}
      />
    </>
  );
}
